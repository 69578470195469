import { DisabilityClaim } from "../domain/claims/disability/DisabilityClaim";
import { ClaimStatus } from "../enums/ClaimStatus";
import { BenefitDecisionType } from "../enums/claims/disability/BenefitDecisionType";
import _ from "lodash";
import { PremiumPaymentType } from "../enums/claims/disability/PremiumPaymentType";
import { SourceSystem } from "../enums/claims/disability/SourceSystem";
import { BenefitType } from "../enums/claims/disability/BenefitType";
import { RequestedInformation } from "../enums/RequestedInformation";
import isWellness = BenefitType.isWellness;
import isLife = BenefitType.isLife;

export function findClaimStatusFor(claim: DisabilityClaim, sourceSystem: SourceSystem): ClaimStatus {
    const benefits = claim.benefits;
    const hasApproval = benefits.some(b => [BenefitDecisionType.APPROVED, BenefitDecisionType.PARTIALLY_DENIED].includes(b.memberStatusIndicator as BenefitDecisionType))
    if(hasApproval) {
        return ClaimStatus.APPROVED;
    }
    const hasAllDenials = benefits.every(b => b.memberStatusIndicator === BenefitDecisionType.DENIED)
    if(hasAllDenials) {
        return ClaimStatus.DENIED
    }
    const requestedInformation = findRequestedInformationFor(claim, sourceSystem);
    if(requestedInformation.length) {
        return ClaimStatus.NEEDS_MORE_INFORMATION
    }
    return ClaimStatus.IN_PROGRESS
}

export function findRequestedInformationFor(claim: DisabilityClaim, sourceSystem: SourceSystem): RequestedInformation[] {
    const benefits = claim.benefits;
    const occupation = claim.occupation;
    const isLifeClaim = benefits.every(b => isLife(b.type));
    const isWellnessClaim = benefits.every(b => isWellness(b.type));
    const isHoursWorkedMissing = !isLifeClaim && _.isNil(occupation.hoursWorkedPerWeek);
    const isClaimsService = SourceSystem.CLAIMS_SERVICE === sourceSystem;
    const isEmployerContributionOrPremiumPaymentMissing = benefits.some(b => !isLife(b.type) && (_.isNil(b.employerContributionPercentage) || PremiumPaymentType.UNKNOWN === b.premiumPaymentType));
    const isEmployerStatementMissing = isClaimsService && !isWellnessClaim && (isHoursWorkedMissing || isEmployerContributionOrPremiumPaymentMissing);
    return isEmployerStatementMissing ? [RequestedInformation.EMPLOYER_CLAIM] : [];
}