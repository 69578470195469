import { QueryResult } from "@apollo/client/react/types/types";
import { FIND_CLAIMS_QUERY } from "../atoms/Queries";
import { DisabilityClaim } from "../domain/claims/disability/DisabilityClaim";
import currency from "currency.js";
import { DateTime } from "luxon";
import { Claim } from "../domain/Claim";
import { ClaimStatus } from "../enums/ClaimStatus";
import { Relationship } from "../enums/Relationship";
import { findKeyByValue, snakeCaseToCapitalized } from "../enums/FindKeyByValue";
import { useCoverage } from "./useCoverage";
import { OperationVariables, useQuery } from "@apollo/client";
import { Coverage } from "../enums/Coverage";
import { SourceSystem } from "../enums/claims/disability/SourceSystem";
import findRelationshipFor = Relationship.findRelationshipFor;
import { BenefitType } from "../enums/claims/disability/BenefitType";
import { findRequestedInformationFor, findClaimStatusFor } from "../service/ClaimStatusService";
import { useMember } from "./useMember";

export function useClaims(): QueryResult {
    const coverage = useCoverage();
    const { loading: claimsLoading, data: claimsData, error: claimsError, ...claimsProps } = useQuery(FIND_CLAIMS_QUERY, {
        variables: {
            coverage: findKeyByValue(Coverage, coverage)
        } as OperationVariables
    })
    const { loading: memberLoading, data: member, error: memberError } = useMember()

    return {
        ...claimsProps,
        loading: claimsLoading || memberLoading,
        data: claimsData?.findClaims?.map((c: DisabilityClaim) => {
            const hasWaiverOfPremiums = c.benefits.some(b => BenefitType.hasWaiverOfPremiums(b.type));
            const payments = c.benefits
                .flatMap(b => b.payments);
            const waiverCurrency = { format: () => ClaimStatus.APPROVED === claimStatus ? 'Life premiums waived (see booklet)' : 'Not applicable' } as currency
            const sourceSystem = (Object.keys(SourceSystem).includes(c.createdBy as SourceSystem) ? c.createdBy : SourceSystem.CLAIMS_SERVICE) as SourceSystem
            const claimStatus = findClaimStatusFor(c, sourceSystem);
            const type = [...new Set(c.benefits.map(b => b.type))]
                .map(t => BenefitType.findClaimTypeFor(t, member?.benefit?.contractState))
                .join(', ')
            return {
                claimId: c.claimId,
                person: SourceSystem.CLAIMS_SERVICE === sourceSystem ? (c.coveredPerson?.firstName || 'Yourself') : '',
                relationship: findRelationshipFor(c.coveredPerson?.dependentType),
                amount: hasWaiverOfPremiums ? waiverCurrency : currency(payments.reduce((a, c) => a + c.netAmount, 0)),
                submitted: DateTime.fromISO(c.notificationDate),
                status: claimStatus,
                statusDate: DateTime.fromISO(c.benefits
                    .flatMap(b => b.statuses.map(s => s.effectiveDate))
                    .sort((e1, e2) => DateTime.fromISO(e2).toMillis() - DateTime.fromISO(e1).toMillis())[0]),
                requestedInformation: findRequestedInformationFor(c, sourceSystem),
                payments: payments.map((p) => ({
                    paymentId: p.paymentId,
                    effectiveDate: DateTime.fromISO(p.currentStatus.effectiveDate),
                    amount: currency(p.netAmount),
                    explanationOfBenefitsDocumentInstanceId: p.edfEmployeeEobDocumentInstanceId,
                    benefits: p.itemizations?.map(i => ({
                        benefit: snakeCaseToCapitalized(i.name),
                        amount: currency(i.paymentAmount)
                    }))
                })),
                documents: c.documents?.map(d => ({
                    name: d.name,
                    sharepointItemId: d.sharepointItemId,
                    creationDate: DateTime.fromISO(d.creationDate)
                })),
                sourceSystem,
                type
            } as Claim
        }) as Claim[],
        error: claimsError || memberError
    }
}
